<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="运营商姓名：">
        <el-input size="small" v-model="form.name" placeholder="请输入运营商姓名"></el-input>
      </el-form-item>
      <el-form-item label="筛选时间：">
        <el-date-picker
          size="small"
          class="search_input"
          v-model="Registerdate"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="15px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button size="small" @click="exportEx">导出</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="indexbox">
      <el-table :data="userList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="mobile" label="运营商姓名" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="授权区域" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.area ? scope.row.area : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" min-width="120" align="center">
          <template #header>
            <div class="header" @click="getMidLeft(form.order)">
              <div class="label">运营商团队佣金收益</div>
              <div class="px">
                <i :style="{ color: form.order == 'asc' ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
                <i class="el-icon-caret-bottom" :style="{ color: form.order == 'desc' ? '#5cb6ff' : '' }"></i>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{ Math.floor(scope.row.amount * 100) / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="运营商自己提现收益" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ Math.floor(scope.row.withdraw_amount * 100) / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="查看细节数据" min-width="120" align="center">
          <template slot-scope="scope">
            <span style="color: blue" @click="viewDataFunc(scope.row.facilitator_id)">查看</span>
          </template>
        </el-table-column>
      </el-table>
      <Paging class="paging" :total="totalNum" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    </div>

    <!-- 查看详情弹窗 -->
    <el-dialog title="查看" :visible.sync="viewData" width="600px">
      <div class="incomeData">
        <div style="width: 46%">
          <div>佣金收益：</div>
          <el-form label-width="130px">
            <el-form-item label=""></el-form-item>
            <el-form-item label="运营商收益金额">{{ Math.floor(earningsInfo.facilitator_earnings * 100) / 100 }}</el-form-item>
            <el-form-item label="商家收益金额">{{ Math.floor(earningsInfo.store_earnings * 100) / 100 }}</el-form-item>
            <el-form-item label="发展商收益金额">{{ Math.floor(earningsInfo.regimental_earnings * 100) / 100 }}</el-form-item>
          </el-form>
        </div>
        <div style="border-left: 1px solid #000; width: 50%; padding-left: 40px">
          <div>提现收益：</div>
          <el-form label-width="130px">
            <el-form-item label=""></el-form-item>
            <el-form-item label="运营商提现金额">{{ Math.floor(earningsInfo.facilitator_withdraw_amount * 100) / 100 }}</el-form-item>
            <el-form-item label="商家提现金额">{{ Math.floor(earningsInfo.store_withdraw_amount * 100) / 100 }}</el-form-item>
            <el-form-item label="	发展商提现金额">{{ Math.floor(earningsInfo.regimental_withdraw_amount * 100) / 100 }}</el-form-item>
          </el-form>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="viewData = false">取 消</el-button>
        <el-button type="primary" @click="viewData = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>
   
<script>
import _ from 'lodash';
import Paging from '@/components/paging.vue';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      realData: [],
      totalNum: {},
      form: {
        page: 1,
        rows: 10,
      },
      Registerdate: null,
      userList: [],

      viewData: false,
      earningsInfo: {},
      facilitator_id: null,
    };
  },
  created() {
    this.getIndexData();
  },
  methods: {
    open() {
      this.$alert('导出中，请耐心等待，稍后跳转到导出文件中下载', '提示', {
        confirmButtonText: '确定',
        callback: action => {},
      });
    },
    getIndexData() {
      if (this.Registerdate) {
        this.form.start_time = this.Registerdate[0] / 1000;
        this.form.end_time = this.Registerdate[1] / 1000;
      }

      this.$axios.post(this.$api.set.earningsStatistics, this.form).then(res => {
        if (res.code == 0) {
          this.userList = res.result.list;
          this.totalNum = res.result.total_number;
        }
      });
    },
    search() {
      this.getIndexData();
    },
    // 下一页
    updateData(val, status) {
      if (status == 0) {
        this.form.rows = val;
        this.getIndexData();
      } else {
        this.form.page = val;
        this.getIndexData();
      }
    },

    // 排序
    getMidLeft(order) {
      if (order == 'asc') {
        this.form.order = 'desc';
      } else {
        this.form.order = 'asc';
      }
      this.getIndexData();
    },

    // 清空搜索
    clearSearch() {
      this.form = {
        page: 1,
        rows: 10,
        order: 'asc',
      };
      this.getIndexData();
    },

    // 导出
    exportEx() {
      this.$axios
        .post(this.$api.set.earningsStatisticsExport, {
          name: this.form.name,
          order: this.form.order,
          start_time: this.Registerdate ? this.Registerdate[0] / 1000 : null,
          end_time: this.Registerdate ? this.Registerdate[1] / 1000 : null,
        })
        .then(res => {
          if (res.code == 0) {
            this.open();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    viewDataFunc(id) {
      this.facilitator_id = id;
      let obj = {};

      obj.facilitator_id = id;
      obj.start_time = this.Registerdate ? this.Registerdate[0] / 1000 : null;
      obj.end_time = this.Registerdate ? this.Registerdate[1] / 1000 : null;

      this.$axios
        .post(this.$api.store.earningsStatisticsInfo, obj)
        .then(res => {
          if (res.code == 0) {
            this.earningsInfo = res.result;
            this.viewData = true;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>
  
<style lang="less" scoped>
.indexbox {
  font-size: 14px;

  .indexBoxLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 8px;
    background-color: #fff;

    .realtime {
      .realtimebot {
        margin-top: 20px;
        padding: 0 30px 23px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .listItem {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;

          .computed {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
          }

          & + .listItem {
            margin-top: 20px;
          }

          .topItem {
            flex-shrink: 0;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            // padding: 12px 20px 0;
            align-items: center;

            .today {
              text-align: left;

              p {
                font-size: 28px;
                font-weight: bold;
                line-height: 30px;
              }

              p:first-child {
                white-space: nowrap;
                font-weight: 400;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .indexBoxRight {
    flex: 10;
    min-width: 478px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      width: 100%;
    }

    .righttop {
      height: 388px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 8px;
      background-color: #fff;

      & > div {
        width: 100%;
        // box-shadow: 0px 2px 16px rgba(86, 84, 243, 0.1);
      }

      .righttopT {
        height: 388px;
        padding: 16px 24px 20px 24px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;

        .titleBox {
          margin-bottom: 12px;
          display: flex;
          justify-content: space-between;

          .title {
            font-size: 14px;
            font-weight: bold;
            color: #17171a;

            &:before {
              content: '';
              height: 14px;
              border-right: 3px solid #1467ff;
              margin-right: 8px;
            }
          }

          .ignoreall {
            color: #979899;
            cursor: pointer;
          }
        }

        .listBox {
          flex: 1;
          padding-right: 10px;
          overflow: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 5px !important;
            height: 5px !important;
          }

          .itemBox {
            display: flex;
            margin-bottom: 12px;
            justify-content: space-between;
            align-items: center;

            .content {
              width: 192px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #656566;

              span {
                display: inline-block;
                width: 4px;
                height: 4px;
                margin-bottom: 2.5px;
                background: #1467ff;
                border-radius: 50%;
                margin-right: 5px;
              }
            }

            .time {
              color: #cacbcc;
              font-size: 12px;
            }

            .todeal {
              color: #1467ff;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.charts {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}

.realtimetop {
  display: flex;
  align-items: center;

  .title {
    font-weight: bold;
    font-size: 14px;
    padding: 20px 0;

    &:before {
      content: '';
      height: 14px;
      margin-right: 8px;
    }

    span,
    i {
      color: #409eff;
    }

    span {
      margin: 0 10px;
      font-size: 15px;
    }

    i {
      transform: rotate(90deg);
      font-size: 18px;
      cursor: pointer;
    }
  }

  .timeTxt {
    margin: 0 8px 0 16px;
    color: #969699;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;

  .px {
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      font-size: 12px;
    }
  }
}

.el-main {
  background: #fff;
  .total-num {
    display: flex;
    font-weight: bold;
    margin: 20px 0;
    div {
      margin-right: 40px;
    }
  }
  .sortList {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}
.incomeData {
  display: flex;
  justify-content: space-between;
}
</style>
  